import React from "react";

import { Column } from "components/Column";
import { Row } from "components/Row";
import { Image } from "components/Image";
import { Text } from "components/Text";
import { Button } from "components/Button";
import { Stack } from "components/Stack";
import { Line } from "components/Line";
import { List } from "components/List";

const NFTpagePage = () => {
  return (
    <>
      <Column className="bg-gray_900 font-gothampro mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Row className="bg-gray_900 items-center justify-between xl:px-[102px] px-[115px] 3xl:px-[138px] lg:px-[89px] lg:py-[13px] xl:py-[15px] py-[17px] 3xl:py-[20px] w-[100%]">
            <Image
              src={"images/img_group7667423.svg"}
              className="lg:h-[10px] xl:h-[11px] h-[12px] 2xl:h-[13px] 3xl:h-[15px] lg:my-[13px] xl:my-[15px] my-[17px] 3xl:my-[20px] object-contain w-[8%]"
              alt="Group7667423"
            />
            <Row className="items-center justify-center lg:ml-[366px] xl:ml-[418px] ml-[471px] 3xl:ml-[565px] w-[43%]">
              <Text className="cursor-pointer hover:font-normal font-normal lg:mb-[11px] xl:mb-[13px] mb-[15px] 3xl:mb-[18px] lg:mt-[12px] xl:mt-[14px] mt-[16px] 3xl:mt-[19px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Roadmap`}</Text>
              <Text className="cursor-pointer hover:font-normal font-normal lg:mb-[11px] xl:mb-[13px] mb-[15px] 3xl:mb-[18px] lg:ml-[31px] xl:ml-[36px] ml-[41px] 3xl:ml-[49px] lg:mt-[12px] xl:mt-[14px] mt-[16px] 3xl:mt-[19px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Collect to earn`}</Text>
              <Text className="cursor-pointer hover:font-normal font-normal lg:mb-[11px] xl:mb-[13px] mb-[15px] 3xl:mb-[18px] lg:ml-[32px] xl:ml-[37px] ml-[42px] 3xl:ml-[50px] lg:mt-[12px] xl:mt-[14px] mt-[16px] 3xl:mt-[19px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Team`}</Text>
              <Text className="cursor-pointer hover:font-normal font-normal lg:mb-[11px] xl:mb-[13px] mb-[15px] 3xl:mb-[18px] lg:ml-[31px] xl:ml-[35px] ml-[40px] 3xl:ml-[48px] lg:mt-[12px] xl:mt-[14px] mt-[16px] 3xl:mt-[19px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`FAQs`}</Text>
              <Button className="bg-indigo_300 font-bold lg:ml-[31px] xl:ml-[35px] ml-[40px] 3xl:ml-[48px] lg:py-[13px] xl:py-[15px] py-[16.875px] 2xl:py-[16px] 3xl:py-[20px] text-center lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-white_A700 w-[29%]">{`Join our Discord`}</Button>
            </Row>
          </Row>
        </header>
        <Row className="items-start justify-start 3xl:mt-[108px] lg:mt-[70px] xl:mt-[80px] mt-[90px] xl:pl-[102px] pl-[115px] 3xl:pl-[138px] lg:pl-[89px] lg:pr-[140px] xl:pr-[161px] pr-[181px] 3xl:pr-[217px] w-[100%]">
          <Column className="items-start justify-start lg:mb-[48px] xl:mb-[55px] mb-[62px] 3xl:mb-[74px] lg:mt-[26px] xl:mt-[30px] mt-[34px] 3xl:mt-[40px] w-[37%]">
            <Text className="font-bold lg:text-fs24 xl:text-fs28 text-fs32 3xl:text-fs38 text-left text-white_A700 uppercase w-[auto]">
              {
                <>
                  {`Discover, collect`}
                  <br />
                  {`and sell`}
                </>
              }
            </Text>
            <Text className="font-black mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[8px] lg:text-fs46 xl:text-fs53 text-fs60 3xl:text-fs72 text-indigo_300 text-left uppercase w-[auto]">{`Jesus NFTs`}</Text>
            <Text className="font-normal leading-lh13000 lg:mt-[41px] xl:mt-[47px] mt-[53px] 3xl:mt-[63px] opacity-op8 lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700 w-[88%]">{`The world's first and largest digital collection of Jesus Artwork in ERC 721 non- fungible tokens (NFTs).`}</Text>
            <Button className="bg-white_A700 font-bold lg:mt-[56px] xl:mt-[64px] mt-[73px] 3xl:mt-[87px] lg:py-[16px] xl:py-[18px] py-[20.875px] 2xl:py-[20px] 3xl:py-[25px] text-black_900 text-center lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 w-[40%]">{`Join our Discord`}</Button>
          </Column>
          <Column className="items-center justify-start 3xl:ml-[110px] lg:ml-[71px] xl:ml-[81px] ml-[92px] w-[36%]">
            <Stack className="lg:h-[311px] xl:h-[355px] h-[399px] 2xl:h-[400px] 3xl:h-[480px] w-[100%]">
              <Image
                src={"images/img_portrait3var5.png"}
                className="absolute lg:h-[259px] xl:h-[296px] h-[332px] 2xl:h-[333px] 3xl:h-[399px] inset-y-[0] my-[auto] object-contain right-[0] w-[49%]"
                alt="portrait3var5"
              />
              <Image
                src={"images/img_portrait1var7.png"}
                className="absolute lg:h-[259px] xl:h-[296px] h-[332px] 2xl:h-[333px] 3xl:h-[399px] inset-y-[0] left-[0] my-[auto] object-contain w-[49%]"
                alt="portrait1var7"
              />
              <Image
                src={"images/img_portrait2var4.png"}
                className="absolute lg:h-[311px] xl:h-[355px] h-[399px] 2xl:h-[400px] 3xl:h-[480px] inset-x-[19%] inset-y-[0] object-contain w-[62%]"
                alt="portrait2var4"
              />
            </Stack>
            <Image
              src={"images/img_group7667146.svg"}
              className="lg:h-[35px] xl:h-[40px] h-[44px] 2xl:h-[45px] 3xl:h-[53px] lg:mt-[14px] xl:mt-[16px] mt-[18px] 3xl:mt-[21px] mx-[auto] object-contain w-[20%]"
              alt="Group7667146"
            />
          </Column>
        </Row>
        <Stack className="font-montserrat 3xl:h-[1094px] lg:h-[709px] xl:h-[811px] h-[911px] 2xl:h-[912px] lg:mt-[34px] xl:mt-[39px] mt-[44px] 3xl:mt-[52px] w-[100%]">
          <Stack className="absolute 3xl:h-[1094px] lg:h-[709px] xl:h-[811px] h-[911px] 2xl:h-[912px] inset-[0] w-[100%]">
            <Column className="absolute bg-gray_901 bottom-[0] items-start justify-end lg:pb-[60px] xl:pb-[69px] pb-[78px] 3xl:pb-[93px] pt-[109px] 3xl:pt-[130px] lg:pt-[84px] xl:pt-[96px] shadow-bs w-[100%]">
              <div className="backdrop-opacity-[0.8] bg-indigo_300 blur-[104.00px] h-[103px] 2xl:h-[104px] 3xl:h-[124px] lg:h-[81px] xl:h-[92px] rounded-radius515 w-[2%]"></div>
              <Column className="items-end lg:mt-[343px] xl:mt-[393px] mt-[442px] 3xl:mt-[530px] lg:pl-[1039px] xl:pl-[1189px] pl-[1337px] 3xl:pl-[1605px] w-[100%]">
                <div className="backdrop-opacity-[0.8] bg-indigo_300 blur-[104.00px] h-[103px] 2xl:h-[104px] 3xl:h-[124px] lg:h-[81px] xl:h-[92px] rounded-radius50 w-[103px] 3xl:w-[123px] lg:w-[80px] xl:w-[91px]"></div>
              </Column>
            </Column>
            <Column className="absolute items-center justify-start right-[10%] top-[0] w-[1%]">
              <Text className="font-normal leading-lh14000 not-italic rotate-[90deg] lg:text-fs11 xl:text-fs13 text-fs15 3xl:text-fs18 text-left text-white_A700 w-[100%]">{`Scroll & Discover`}</Text>
              <Line className="bg-white_A700 h-[102px] 2xl:h-[103px] 3xl:h-[123px] lg:h-[80px] xl:h-[91px] mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[8px] mx-[auto] rotate-[90deg] w-[1px]" />
            </Column>
          </Stack>
          <Column className="absolute bottom-[14%] font-gothampro inset-x-[0] items-start justify-start mx-[auto] w-[55%]">
            <Text className="font-black lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 text-left uppercase w-[auto]">{`About us`}</Text>
            <Line className="bg-white_A700 h-[2px] lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[5%]" />
            <Text className="font-medium leading-lh15000 lg:mt-[20px] xl:mt-[23px] mt-[26px] 3xl:mt-[31px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700 w-[100%]">
              <span className="text-white_A700 font-gothampro">
                <>
                  {`At The Global Architect Institute, we are highly interested in the Simulation Hypothesis and how it may play into the Second Coming of Christ.`}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {`Simulation Theory argues that everything in our universe, including our very existence as human beings, may simply be part of an Artiﬁcial Simulation. `}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {`A concept once reserved for sci-fi fans, the idea that humanity and the universe exist within a computer program (much like in `}
                </>
              </span>
              <span className="text-white_A700 font-gothampro italic">
                <>{`The Matrix`}</>
              </span>
              <span className="text-white_A700 font-gothampro">
                <>
                  {`) has grown continually in popularity. Now, the Simulation Hypothesis is entertained by secular believers and prominent scientists alike and has been explored by fields of research including Quantum Physics and Artiﬁcial Intelligence.`}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {`By merging Simulation Hypothesis and Religion, we at the Global Architect Institute have created a new branch of Creationism, which we call "Simulation Creationism."`}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {`Our Institute has gained tens of thousands of enthusiastic followers in a very short period of time, and we are growing by the day as tech-savvy Christians around the world discover our creed to be entirely compatible with the Bible’s version of Creation.`}
                </>
              </span>
            </Text>
          </Column>
        </Stack>
        <Column className="font-gothampro items-center justify-start xl:mt-[106px] mt-[120px] 3xl:mt-[144px] lg:mt-[93px] w-[100%]">
          <Stack className="lg:h-[409px] xl:h-[468px] h-[525px] 2xl:h-[526px] 3xl:h-[631px] mx-[auto] w-[84%]">
            <div className="absolute bg-black_901 bottom-[0] lg:h-[307px] xl:h-[351px] h-[394px] 2xl:h-[395px] 3xl:h-[474px] right-[0] w-[83%]"></div>
            <Row className="absolute items-start justify-between left-[0] top-[0] w-[89%]">
              <div className="border-bw3 border-indigo_300 border-solid lg:h-[323px] xl:h-[370px] h-[415px] 2xl:h-[416px] 3xl:h-[499px] lg:mb-[14px] xl:mb-[16px] mb-[19px] 3xl:mb-[22px] lg:w-[322px] xl:w-[369px] w-[415px] 3xl:w-[498px]"></div>
              <Column className="items-start justify-start lg:ml-[112px] xl:ml-[128px] ml-[145px] 3xl:ml-[174px] w-[48%]">
                <Text className="font-black lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 text-left uppercase w-[auto]">{`Company Profile`}</Text>
                <Line className="bg-white_A700 h-[2px] lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[8%]" />
                <Text className="font-normal leading-lh15000 xl:mt-[104px] mt-[118px] 3xl:mt-[141px] lg:mt-[91px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                  {
                    <>
                      {`The Global Architect Institute is a non-proﬁt organization under IRC Section 501(c)(3), which means we are heavily regulated by the Federal government and 100% of our income is spent on activities by the institute. `}
                      <br />
                      {``}
                      <br />
                      {`Donations to 501(c)(3) nonproﬁts are tax-deductible.`}
                      <br />
                      {``}
                      <br />
                      {``}
                      <br />
                      {``}
                      <br />
                      {`Visit our website to discover hundreds of original articles by front-running researchers and figures from a range of related fields.`}
                    </>
                  }
                </Text>
              </Column>
            </Row>
            <Image
              src={"images/img_group7667414.svg"}
              className="absolute lg:h-[323px] xl:h-[370px] h-[415px] 2xl:h-[416px] 3xl:h-[499px] inset-y-[0] left-[4%] my-[auto] object-contain lg:w-[322px] xl:w-[369px] w-[415px] 3xl:w-[498px]"
              alt="Group7667414"
            />
          </Stack>
          <Row className="bg-black_901 items-start justify-start xl:mt-[106px] mt-[120px] 3xl:mt-[144px] lg:mt-[93px] 3xl:pb-[102px] lg:pb-[66px] xl:pb-[75px] pb-[85px] xl:pr-[102px] pr-[115px] 3xl:pr-[138px] lg:pr-[89px] xl:pt-[106px] pt-[120px] 3xl:pt-[144px] lg:pt-[93px] w-[100%]">
            <Stack className="lg:h-[441px] xl:h-[505px] h-[567px] 2xl:h-[568px] 3xl:h-[681px] lg:mb-[6px] xl:mb-[7px] mb-[8px] 3xl:mb-[9px] w-[39%]">
              <Image
                src={"images/img_.svg"}
                className="absolute lg:h-[133px] xl:h-[152px] h-[169.91px] 2xl:h-[171px] 3xl:h-[205px] left-[0] object-contain opacity-op6 top-[8%] w-[20%]"
                alt=""
              />
              <Column className="absolute items-start justify-start right-[0] w-[80%]">
                <Text className="font-black lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 text-left uppercase w-[auto]">{`Social Proof`}</Text>
                <Line className="bg-white_A700 h-[2px] lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[10%]" />
                <Text className="font-normal leading-lh15000 lg:mt-[27px] xl:mt-[31px] mt-[35px] 3xl:mt-[42px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700 w-[100%]">
                  <span className="text-white_A700 font-gothampro">
                    <>
                      {`Since the inception of The Global Architect Institute at the end of 2021, we’re proud to have welcomed an incredible amount of supporters. `}
                      <br />
                      {``}
                      <br />
                      {`Check out `}
                    </>
                  </span>
                  <span className="text-indigo_300 font-gothampro font-medium">
                    <>{`our official Facebook page`}</>
                  </span>
                  <span className="text-white_A700 font-gothampro">
                    <>
                      {`, which has over 22,000 followers. With 99% of them based in the USA, we’re excited to continue spreading the global message of Simulation Creationism with our venture into the decentralized future. `}
                      <br />
                      {``}
                      <br />
                      {`Each of our posts, which delve into the exhilarating union between science and religion, receives thousands of Likes and hundreds of passionate comments by believers. `}
                      <br />
                      {``}
                      <br />
                      {``}
                      <br />
                      {`No matter where in the world you are, we invite you to share our message on social media.`}
                      <br />
                      {``}
                      <br />
                      {``}
                    </>
                  </span>
                  <span className="text-indigo_300 font-gothampro font-medium">
                    <>{`He is coming - and we shall be ready!`}</>
                  </span>
                </Text>
              </Column>
            </Stack>
            <Stack className="lg:h-[448px] xl:h-[512px] h-[575px] 2xl:h-[576px] 3xl:h-[691px] 3xl:ml-[117px] lg:ml-[76px] xl:ml-[87px] ml-[98px] w-[46%]">
              <Stack className="absolute bottom-[0] lg:h-[378px] xl:h-[432px] h-[485px] 2xl:h-[486px] 3xl:h-[583px] left-[0] w-[91%]">
                <div className="absolute backdrop-opacity-[0.2] bg-indigo_300 blur-[104.00px] bottom-[0] lg:h-[243px] xl:h-[278px] h-[312px] 2xl:h-[313px] 3xl:h-[375px] right-[3%] rounded-radius50 lg:w-[242px] xl:w-[277px] w-[312px] 3xl:w-[374px]"></div>
                <List
                  className="absolute bottom-[15%] lg:gap-[23px] xl:gap-[26px] gap-[30px] 3xl:gap-[36px] grid grid-cols-2 min-h-[auto] w-[100%]"
                  orientation="horizontal"
                >
                  <Stack className="lg:h-[125px] xl:h-[143px] h-[160px] 2xl:h-[161px] 3xl:h-[193px] lg:mt-[107px] xl:mt-[122px] mt-[138px] 3xl:mt-[165px] w-[100%]">
                    <Column className="absolute bg-gray_900_e5 bottom-[0] items-start justify-start lg:p-[15px] xl:p-[17px] p-[20px] 3xl:p-[24px] shadow-bs1 w-[100%]">
                      <Text className="font-medium lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Arnaldo Alameda`}</Text>
                      <Text className="font-normal leading-lh15000 lg:mt-[11px] xl:mt-[13px] mt-[15px] 3xl:mt-[18px] opacity-op9 lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[88%]">{`Amazing inspiring for all those true Believer that really appreciate believe in God. Almighty himself Amen!`}</Text>
                    </Column>
                    <Image
                      src={"images/img_group7667417.svg"}
                      className="absolute lg:h-[28px] xl:h-[33px] h-[36px] 2xl:h-[37px] 3xl:h-[44px] object-contain right-[4%] top-[0] lg:w-[28px] xl:w-[32px] w-[36px] 3xl:w-[43px]"
                      alt="Group7667417"
                    />
                  </Stack>
                  <Stack className="lg:h-[125px] xl:h-[143px] h-[160px] 2xl:h-[161px] 3xl:h-[193px] lg:mb-[107px] xl:mb-[122px] mb-[138px] 3xl:mb-[165px] w-[100%]">
                    <Column className="absolute bg-gray_900_e5 bottom-[0] items-start justify-start lg:p-[15px] xl:p-[17px] p-[20px] 3xl:p-[24px] shadow-bs1 w-[100%]">
                      <Text className="font-medium lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Debbie Beatty`}</Text>
                      <Text className="font-normal leading-lh15000 lg:mt-[11px] xl:mt-[13px] mt-[15px] 3xl:mt-[18px] opacity-op9 lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[83%]">{`Glory to God, our Lord and Savior Jesus and the Holy Spirit. Amen!`}</Text>
                    </Column>
                    <Image
                      src={"images/img_group7667415.svg"}
                      className="absolute lg:h-[28px] xl:h-[33px] h-[36px] 2xl:h-[37px] 3xl:h-[44px] object-contain right-[6%] top-[0] lg:w-[28px] xl:w-[32px] w-[36px] 3xl:w-[43px]"
                      alt="Group7667415"
                    />
                  </Stack>
                </List>
                <Column className="absolute bg-gray_900_e5 items-start justify-start left-[13%] lg:p-[15px] xl:p-[17px] p-[20px] 3xl:p-[24px] shadow-bs1 top-[4%] w-[43%]">
                  <Text className="font-medium lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Dixie Matthews`}</Text>
                  <Text className="font-normal leading-lh15000 lg:mt-[11px] xl:mt-[13px] mt-[15px] 3xl:mt-[18px] opacity-op9 lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[85%]">{`Believe me God and Jesus is not an illusion, He is readl and living in our hearts, we will see Him face to face in Heaven! If not sooner!`}</Text>
                </Column>
                <Image
                  src={"images/img_group7667418.svg"}
                  className="absolute lg:h-[28px] xl:h-[33px] h-[36px] 2xl:h-[37px] 3xl:h-[44px] inset-x-[0] mx-[auto] object-contain top-[0] lg:w-[28px] xl:w-[32px] w-[36px] 3xl:w-[43px]"
                  alt="Group7667418"
                />
              </Stack>
              <Column className="absolute bg-gray_900_e5 items-start justify-start lg:p-[15px] xl:p-[17px] p-[20px] 3xl:p-[24px] right-[0] shadow-bs1 top-[3%] w-[44%]">
                <Text className="font-medium lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[auto]">{`Hope Watkins`}</Text>
                <Text className="font-normal leading-lh15000 lg:mt-[11px] xl:mt-[13px] mt-[15px] 3xl:mt-[18px] opacity-op9 lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700 w-[86%]">{`Wow, I love you So much Jesus Christ, I am so Grateful for your Love Amazing me, I Praise you Father God Amen and Amen`}</Text>
              </Column>
              <Image
                src={"images/img_group7667415_1.svg"}
                className="absolute lg:h-[28px] xl:h-[33px] h-[36px] 2xl:h-[37px] 3xl:h-[44px] object-contain right-[2%] top-[0] lg:w-[28px] xl:w-[32px] w-[36px] 3xl:w-[43px]"
                alt="Group7667415"
              />
            </Stack>
          </Row>
          <Row className="items-start justify-start xl:mt-[106px] mt-[120px] 3xl:mt-[144px] lg:mt-[93px] lg:pr-[146px] xl:pr-[167px] pr-[188px] 3xl:pr-[225px] w-[100%]">
            <div className="backdrop-opacity-[0.4] bg-indigo_300 blur-[104.00px] lg:h-[137px] xl:h-[157px] h-[176px] 2xl:h-[177px] 3xl:h-[212px] lg:mb-[197px] xl:mb-[225px] mb-[254px] 3xl:mb-[304px] lg:mt-[16px] xl:mt-[18px] mt-[21px] 3xl:mt-[25px] rotate-[-180deg] rounded-radius88 w-[9%]"></div>
            <Column className="items-start justify-start 3xl:ml-[114px] lg:ml-[73px] xl:ml-[84px] ml-[95px] w-[72%]">
              <Text className="font-black lg:ml-[255px] xl:ml-[292px] ml-[329px] 3xl:ml-[395px] lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 text-left uppercase w-[auto]">{`our project`}</Text>
              <Column className="items-center lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[100%]">
                <Line className="bg-white_A700 h-[2px] mx-[auto] w-[4%]" />
                <Text className="font-bold leading-lh15000 lg:mt-[20px] xl:mt-[23px] mt-[26px] 3xl:mt-[31px] mx-[auto] text-center lg:text-fs15 xl:text-fs17 text-fs20 3xl:text-fs24 text-white_A700_e5 w-[97%]">
                  {
                    <>
                      {`When the Second Coming occurs, Jesus shall be returning to a very different world altogether!`}
                      <br />
                      {``}
                      <br />
                      {`Our premier project is titled “The Glory of Futuristic Christ”, as we use digital artworks and Web3 technology to explore the potential forms Jesus may take when arriving back to a modern world driven by data and decentralization.`}
                    </>
                  }
                </Text>
                <Stack className="lg:h-[158px] xl:h-[180px] h-[202px] 2xl:h-[203px] 3xl:h-[243px] lg:mt-[23px] xl:mt-[26px] mt-[30px] 3xl:mt-[36px] w-[100%]">
                  <div className="absolute backdrop-opacity-[0.5] bg-indigo_300 blur-[104.00px] bottom-[0] h-[103px] 2xl:h-[104px] 3xl:h-[124px] lg:h-[81px] xl:h-[92px] right-[0] rotate-[-180deg] rounded-radius50 w-[103px] 3xl:w-[123px] lg:w-[80px] xl:w-[91px]"></div>
                  <Text className="absolute font-normal inset-x-[0] leading-lh15000 mx-[auto] text-center lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-white_A700_e5 top-[0] w-[97%]">
                    {
                      <>
                        {`How will he appear to us?`}
                        <br />
                        {``}
                        <br />
                        {`What forms will he take?`}
                        <br />
                        {``}
                        <br />
                        {`And how will he display his miracle abilities?`}
                        <br />
                        {``}
                        <br />
                        {`Together, let’s prepare for the Second Coming of Futuristic Christ. `}
                        <br />
                        {``}
                        <br />
                        {``}
                        <br />
                        {``}
                        <br />
                        {`Glorious, it shall be!`}
                      </>
                    }
                  </Text>
                </Stack>
              </Column>
            </Column>
          </Row>
          <Row className="items-center justify-between lg:mt-[54px] xl:mt-[62px] mt-[70px] 3xl:mt-[84px] overflow-auto w-[100%]">
            <Image
              src={"images/img_dropmefilescom.png"}
              className="lg:h-[218px] xl:h-[250px] h-[280px] 2xl:h-[281px] 3xl:h-[337px] lg:my-[27px] xl:my-[31px] my-[35px] 3xl:my-[42px] object-contain opacity-op3 lg:w-[217px] xl:w-[249px] w-[280px] 3xl:w-[336px]"
              alt="dropmefilescom"
            />
            <Image
              src={"images/img_futuristikjesu.png"}
              className="lg:h-[218px] xl:h-[250px] h-[280px] 2xl:h-[281px] 3xl:h-[337px] lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:my-[27px] xl:my-[31px] my-[35px] 3xl:my-[42px] object-contain opacity-op3 lg:w-[217px] xl:w-[249px] w-[280px] 3xl:w-[336px]"
              alt="futuristikjesu"
            />
            <Image
              src={"images/img_varianttwobab.png"}
              className="lg:h-[273px] xl:h-[312px] h-[350px] 2xl:h-[351px] 3xl:h-[421px] lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] object-contain lg:w-[272px] xl:w-[311px] w-[350px] 3xl:w-[420px]"
              alt="varianttwoBAB"
            />
            <Image
              src={"images/img_portrait2var4_1.png"}
              className="lg:h-[218px] xl:h-[250px] h-[280px] 2xl:h-[281px] 3xl:h-[337px] lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:my-[27px] xl:my-[31px] my-[35px] 3xl:my-[42px] object-contain opacity-op3 lg:w-[217px] xl:w-[249px] w-[280px] 3xl:w-[336px]"
              alt="portrait2var4"
            />
            <Image
              src={"images/img_portrait1var7_1.png"}
              className="lg:h-[218px] xl:h-[250px] h-[280px] 2xl:h-[281px] 3xl:h-[337px] lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:my-[27px] xl:my-[31px] my-[35px] 3xl:my-[42px] object-contain opacity-op3 lg:w-[217px] xl:w-[249px] w-[280px] 3xl:w-[336px]"
              alt="portrait1var7"
            />
            <Image
              src={"images/img_portrait3var5_1.png"}
              className="lg:h-[218px] xl:h-[250px] h-[280px] 2xl:h-[281px] 3xl:h-[337px] lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:my-[27px] xl:my-[31px] my-[35px] 3xl:my-[42px] object-contain opacity-op3 lg:w-[217px] xl:w-[249px] w-[280px] 3xl:w-[336px]"
              alt="portrait3var5"
            />
          </Row>
          <Image
            src={"images/img_group7667146_1.svg"}
            className="lg:h-[35px] xl:h-[40px] h-[44px] 2xl:h-[45px] 3xl:h-[53px] lg:mt-[46px] xl:mt-[53px] mt-[60px] 3xl:mt-[72px] mx-[auto] object-contain w-[7%]"
            alt="Group7667146"
          />
        </Column>
        <Stack className="font-gothampro lg:h-[2652px] xl:h-[3033px] h-[3409px] 2xl:h-[3412px] 3xl:h-[4094px] xl:mt-[106px] mt-[120px] 3xl:mt-[144px] lg:mt-[93px] w-[100%]">
          <Stack className="absolute lg:h-[2652px] xl:h-[3033px] h-[3409px] 2xl:h-[3412px] 3xl:h-[4094px] inset-[0] w-[100%]">
            <div className="absolute backdrop-opacity-[0.3] bg-indigo_300 blur-[104.00px] lg:h-[193px] xl:h-[221px] h-[248px] 2xl:h-[249px] 3xl:h-[298px] left-[0] rotate-[-180deg] rounded-radius124 top-[38%] w-[12%]"></div>
            <Row className="absolute bottom-[0] items-start justify-between right-[0] w-[85%]">
              <Column className="items-center justify-start w-[82%]">
                <Text className="font-black font-gothampro mx-[auto] text-center lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 uppercase w-[auto]">{`roadmap`}</Text>
                <Text className="font-inter font-normal leading-lh15000 lg:mt-[54px] xl:mt-[62px] mt-[70px] 3xl:mt-[84px] mx-[auto] not-italic opacity-op9 text-center lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-white_A700 w-[79%]">
                  {
                    <>
                      {`The thrilling adventure of our project is only just beginning - will you be joining our decentralized celebration of Simulation Creationism and the Second Coming? `}
                      <br />
                      {``}
                      <br />
                      {``}
                      <br />
                      {``}
                      <br />
                      {`Our project is devoted to capitalizing upon the value of our NFTs and dedicated to maximizing value for our beloved investors. As a community-focused venture, we are all about bringing our supporters the greatest possible benefits!`}
                    </>
                  }
                </Text>
                <Stack className="font-gothampro lg:h-[1254px] xl:h-[1435px] h-[1612px] 2xl:h-[1614px] 3xl:h-[1936px] lg:mt-[54px] xl:mt-[62px] mt-[70px] 3xl:mt-[84px] w-[100%]">
                  <Row className="absolute items-center justify-between left-[0] w-[50%]">
                    <Column className="items-start lg:mt-[41px] xl:mt-[47px] mt-[53px] 3xl:mt-[63px] w-[84%]">
                      <Text className="font-black lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`Acquire Virtual Land`}</Text>
                      <Text className="font-normal leading-lh15000 xl:mt-[10px] mt-[12px] 3xl:mt-[14px] lg:mt-[9px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                        <span className="text-white_A700_e5 font-gothampro">
                          <>
                            {`In October 2022, we shall purchase a plot of metaverse land, where we shall begin construction of The Global Architect Church. It shall be a glorious place to worship, socialize and prepare for the Second Coming together.`}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {`NTF holders of our Pioneer Series will be `}
                          </>
                        </span>
                        <span className="text-indigo_300 font-gothampro font-medium">
                          <>{`entitled to lifetime free entry and participation at all of The Global Architect Church events.`}</>
                        </span>
                      </Text>
                      <Text className="font-black lg:mt-[259px] xl:mt-[297px] mt-[334px] 3xl:mt-[401px] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`Fund Allocation`}</Text>
                      <Text className="font-normal leading-lh15000 xl:mt-[10px] mt-[12px] 3xl:mt-[14px] lg:mt-[9px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                        {
                          <>
                            {`In addition to funding the construction of The Global Architect Metaverse Church, funds generated during our mint shall be used to create engaging Simulation Creationism content, and to fuel PR activity for us to spread our message of the Second Coming far and wide.`}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {`We shall use funds in outreach to elite and institutional donors who are excited to play their part in this digital celebration of the Second Coming.`}
                          </>
                        }
                      </Text>
                      <Text className="font-black lg:mt-[301px] xl:mt-[345px] mt-[388px] 3xl:mt-[465px] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`Further Minting`}</Text>
                      <Text className="font-normal leading-lh15000 xl:mt-[10px] mt-[12px] 3xl:mt-[14px] lg:mt-[9px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                        <span className="text-white_A700_e5 font-gothampro">
                          <>
                            {`After Nir Ziso publishes New Theory, we plan to hold a Second Minting.  `}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                          </>
                        </span>
                        <span className="text-indigo_300 font-gothampro font-medium">
                          <>{`Holders of 3 (or more) NFTs will get 1 NFT (per 3 NFTs) airdrop at our Second Minting.`}</>
                        </span>
                      </Text>
                    </Column>
                    <Line className="bg-white_A700 lg:h-[1254px] xl:h-[1435px] h-[1612px] 2xl:h-[1614px] 3xl:h-[1936px] lg:ml-[59px] xl:ml-[68px] ml-[77px] 3xl:ml-[92px] rounded-radius10 w-[3px]" />
                  </Row>
                  <Row className="absolute items-start justify-between right-[0] top-[3%] w-[53%]">
                    <Column className="w-[11%]">
                      <Column className="bg-gray_900 border-bw3 border-solid border-white_A700 lg:h-[44px] xl:h-[50px] h-[56px] 2xl:h-[57px] 3xl:h-[68px] items-center justify-start lg:pb-[11px] xl:pb-[13px] pb-[15px] 3xl:pb-[18px] xl:pl-[19px] pl-[22px] 3xl:pl-[26px] xl:pr-[20px] pr-[23px] 3xl:pr-[27px] lg:pt-[12px] xl:pt-[14px] pt-[16px] 3xl:pt-[19px] lg:px-[17px] rounded-radius50 w-[100%]">
                        <Text className="font-bold mx-[auto] text-center lg:text-fs20 xl:text-fs23 text-fs26 3xl:text-fs31 text-white_A700 uppercase w-[auto]">{`1`}</Text>
                      </Column>
                      <Column className="bg-gray_900 border-bw3 border-solid border-white_A700 lg:h-[44px] xl:h-[50px] h-[56px] 2xl:h-[57px] 3xl:h-[68px] items-center justify-start lg:mt-[219px] xl:mt-[250px] mt-[282px] 3xl:mt-[338px] lg:pb-[11px] xl:pb-[13px] pb-[15px] 3xl:pb-[18px] lg:pl-[14px] xl:pl-[16px] pl-[19px] 3xl:pl-[22px] lg:pr-[15px] xl:pr-[17px] pr-[20px] 3xl:pr-[24px] lg:pt-[12px] xl:pt-[14px] pt-[16px] 3xl:pt-[19px] rounded-radius50 w-[100%]">
                        <Text className="font-bold mx-[auto] text-center lg:text-fs20 xl:text-fs23 text-fs26 3xl:text-fs31 text-white_A700 uppercase w-[auto]">{`2`}</Text>
                      </Column>
                      <Column className="bg-gray_900 border-bw3 border-solid border-white_A700 lg:h-[44px] xl:h-[50px] h-[56px] 2xl:h-[57px] 3xl:h-[68px] items-center justify-start lg:mt-[177px] xl:mt-[202px] mt-[228px] 3xl:mt-[273px] lg:pb-[11px] xl:pb-[13px] pb-[15px] 3xl:pb-[18px] lg:pl-[14px] xl:pl-[16px] pl-[19px] 3xl:pl-[22px] lg:pr-[15px] xl:pr-[17px] pr-[20px] 3xl:pr-[24px] lg:pt-[12px] xl:pt-[14px] pt-[16px] 3xl:pt-[19px] rounded-radius50 w-[100%]">
                        <Text className="font-bold mx-[auto] text-center lg:text-fs20 xl:text-fs23 text-fs26 3xl:text-fs31 text-white_A700 uppercase w-[auto]">{`3`}</Text>
                      </Column>
                      <Column className="bg-gray_900 border-bw3 border-solid border-white_A700 lg:h-[44px] xl:h-[50px] h-[56px] 2xl:h-[57px] 3xl:h-[68px] items-center justify-start lg:mt-[282px] xl:mt-[322px] mt-[363px] 3xl:mt-[435px] lg:pb-[11px] xl:pb-[13px] pb-[15px] 3xl:pb-[18px] lg:pt-[12px] xl:pt-[14px] pt-[16px] 3xl:pt-[19px] lg:px-[14px] xl:px-[16px] px-[19px] 3xl:px-[22px] rounded-radius50 w-[100%]">
                        <Text className="font-bold mx-[auto] text-center lg:text-fs20 xl:text-fs23 text-fs26 3xl:text-fs31 text-white_A700 uppercase w-[auto]">{`4`}</Text>
                      </Column>
                      <Column className="bg-gray_900 border-bw3 border-solid border-white_A700 lg:h-[44px] xl:h-[50px] h-[56px] 2xl:h-[57px] 3xl:h-[68px] items-center justify-start lg:mt-[219px] xl:mt-[250px] mt-[282px] 3xl:mt-[338px] lg:pb-[11px] xl:pb-[13px] pb-[15px] 3xl:pb-[18px] lg:pl-[14px] xl:pl-[16px] pl-[19px] 3xl:pl-[22px] lg:pr-[15px] xl:pr-[17px] pr-[20px] 3xl:pr-[24px] lg:pt-[12px] xl:pt-[14px] pt-[16px] 3xl:pt-[19px] rounded-radius50 w-[100%]">
                        <Text className="font-bold mx-[auto] text-center lg:text-fs20 xl:text-fs23 text-fs26 3xl:text-fs31 text-white_A700 uppercase w-[auto]">{`5`}</Text>
                      </Column>
                    </Column>
                    <Column className="items-start justify-start mb-[106px] 3xl:mb-[127px] lg:mb-[82px] xl:mb-[94px] lg:ml-[38px] xl:ml-[44px] ml-[50px] 3xl:ml-[60px] lg:mt-[262px] xl:mt-[300px] mt-[338px] 3xl:mt-[405px] w-[80%]">
                      <Text className="font-black lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`Reward Supporters`}</Text>
                      <Text className="font-normal leading-lh15000 xl:mt-[10px] mt-[12px] 3xl:mt-[14px] lg:mt-[9px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                        <span className="text-white_A700_e5 font-gothampro">
                          <>{`Those who mint our Pioneer Series of Futuristic Christ NFTs shall be considered as `}</>
                        </span>
                        <span className="text-indigo_300 font-gothampro font-medium">
                          <>{`"First Supporters"`}</>
                        </span>
                        <span className="text-white_A700_e5 font-gothampro">
                          <>
                            {`, which will entitle them to lifetime entry to webinars and our Metaverse.`}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {`They shall also be celebrated with inclusion in our website’s `}
                          </>
                        </span>
                        <span className="text-indigo_300 font-gothampro font-medium">
                          <>{`"First Supporters"`}</>
                        </span>
                        <span className="text-white_A700_e5 font-gothampro">
                          <>
                            {` Hall of Fame!`}
                            <br />
                            {``}
                          </>
                        </span>
                      </Text>
                      <Text className="font-black lg:mt-[364px] xl:mt-[417px] mt-[469px] 3xl:mt-[563px] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`New Theory`}</Text>
                      <Text className="font-normal leading-lh15000 xl:mt-[10px] mt-[12px] 3xl:mt-[14px] lg:mt-[9px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
                        {
                          <>
                            {`In December 2022, the Founder of The Global Architect Institute, Nir Ziso, will publish his New Theory - a treatise on Simulation Creationism, the crux of science and religion, and his perspective on the Second Coming.`}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {``}
                            <br />
                            {`His new model will create vast interest in the activities of the Institute - both virtual and IRL - and attract many followers and donors.`}
                          </>
                        }
                      </Text>
                    </Column>
                  </Row>
                </Stack>
              </Column>
              <div className="backdrop-opacity-[0.3] bg-indigo_300 blur-[104.00px] lg:h-[135px] xl:h-[154px] h-[173px] 2xl:h-[174px] 3xl:h-[208px] lg:mb-[581px] xl:mb-[665px] mb-[748px] 3xl:mb-[898px] ml-[102px] 3xl:ml-[122px] lg:ml-[79px] xl:ml-[90px] mt-[1036px] 3xl:mt-[1243px] lg:mt-[805px] xl:mt-[921px] rotate-[-180deg] rounded-radius865 w-[9%]"></div>
            </Row>
            <Row className="absolute bg-black_901 items-start justify-start lg:pb-[110px] xl:pb-[126px] pb-[142px] 3xl:pb-[170px] xl:pr-[102px] pr-[115px] 3xl:pr-[138px] lg:pr-[89px] xl:pt-[106px] pt-[120px] 3xl:pt-[144px] lg:pt-[93px] top-[0] w-[100%]">
              <div className="bg-indigo_301 lg:h-[129px] xl:h-[147px] h-[165px] 2xl:h-[166px] 3xl:h-[199px] lg:mb-[455px] xl:mb-[521px] mb-[586px] 3xl:mb-[703px] lg:mt-[248px] xl:mt-[283px] mt-[319px] 3xl:mt-[383px] opacity-op1 rounded-radius825 w-[5%]"></div>
              <Column className="items-start justify-start lg:mb-[128px] xl:mb-[146px] mb-[165px] 3xl:mb-[198px] lg:ml-[38px] xl:ml-[43px] ml-[49px] 3xl:ml-[58px] w-[34%]">
                <Text className="font-black lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-indigo_300 text-left uppercase w-[auto]">{`our art`}</Text>
                <Line className="bg-white_A700 h-[2px] lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[9%]" />
                <Text className="font-medium leading-lh17000 lg:mt-[19px] xl:mt-[22px] mt-[25px] 3xl:mt-[30px] opacity-op9 lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[100%]">
                  {
                    <>
                      {`The Glory of Futuristic Christ features 100 original, hand-crafted paintings of Jesus in a stunning array of modern forms.`}
                      <br />
                      {``}
                      <br />
                      {`From the masses of digital artworks, we stand unique with the provision of 100 unique, real paintings. True art of real meaning!`}
                    </>
                  }
                </Text>
                <Text className="font-medium leading-lh17000 lg:mt-[40px] xl:mt-[46px] mt-[52px] 3xl:mt-[62px] opacity-op9 lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[100%]">{`Created in a range of styles by an exciting collective of acclaimed Christian artists around the world, each Glory of Futuristic Christ painting will feature 25 unique variations, for a total of 2,500 Futuristic Christ NFTs that make up our highly exclusive artwork collection!`}</Text>
                <Text className="font-medium leading-lh17000 lg:mt-[40px] xl:mt-[46px] mt-[52px] 3xl:mt-[62px] opacity-op9 lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[100%]">{`As well as being fantastic visions of future Jesus and wonderful collectors’ items, each Futuristic Christ NFT will unlock a range of thrilling utilities as we celebrate every member of our valued community.`}</Text>
              </Column>
              <Column className="items-start justify-start xl:ml-[100px] ml-[113px] 3xl:ml-[135px] lg:ml-[87px] lg:mt-[6px] xl:mt-[7px] mt-[8px] 3xl:mt-[9px] w-[42%]">
                <Text className="font-bold lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] opacity-op9 lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-indigo_300 text-left w-[auto]">{`As an NFT holder, you will be blessed with:`}</Text>
                <Stack className="lg:h-[39px] xl:h-[44px] h-[49px] 2xl:h-[50px] 3xl:h-[59px] lg:mt-[30px] xl:mt-[34px] mt-[39px] 3xl:mt-[46px] w-[39%]">
                  <div className="absolute bg-indigo_301 lg:h-[26px] xl:h-[30px] h-[33px] 2xl:h-[34px] 3xl:h-[40px] left-[0] rounded-radius50 top-[0] lg:w-[25px] xl:w-[29px] w-[33px] 3xl:w-[39px]"></div>
                  <Text className="absolute bottom-[0] font-black right-[0] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`VIP Content Access`}</Text>
                </Stack>
                <Text className="font-normal leading-lh15000 lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:mt-[15px] xl:mt-[17px] mt-[20px] 3xl:mt-[24px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700_cc w-[58%]">{`At The Global Architect Institute, we cultivate content on the crux of Christianity and Science as part of our exploration of Simulation Creationism. As an NFT holder, you enjoy total access to this ever-expanding library wirth exclusive research and writings by hallowed figures from many fields!`}</Text>
                <Column className="items-end lg:mt-[31px] xl:mt-[35px] mt-[40px] 3xl:mt-[48px] lg:pl-[134px] xl:pl-[153px] pl-[173px] 3xl:pl-[207px] w-[100%]">
                  <Stack className="lg:h-[39px] xl:h-[44px] h-[49px] 2xl:h-[50px] 3xl:h-[59px] lg:mr-[24px] xl:mr-[27px] mr-[31px] 3xl:mr-[37px] w-[67%]">
                    <div className="absolute bg-indigo_301 lg:h-[26px] xl:h-[30px] h-[33px] 2xl:h-[34px] 3xl:h-[40px] left-[0] rounded-radius50 top-[0] lg:w-[25px] xl:w-[29px] w-[33px] 3xl:w-[39px]"></div>
                    <Text className="absolute bottom-[0] font-black right-[0] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`VIP Seats at our Metaverse Church`}</Text>
                  </Stack>
                  <Text className="font-normal leading-lh15000 lg:mt-[15px] xl:mt-[17px] mt-[20px] 3xl:mt-[24px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700_cc w-[68%]">{`As our initial release grows in popularity (and we look to future projects), The Global Architect Institute will purchase land in the metaverse and begin to build a virtual church. As an NFT holder, you’ll always have a VIP seat whenever you wish to stop by and share in our community’s worship.`}</Text>
                </Column>
                <Stack className="lg:h-[39px] xl:h-[44px] h-[49px] 2xl:h-[50px] 3xl:h-[59px] lg:mt-[31px] xl:mt-[35px] mt-[40px] 3xl:mt-[48px] w-[51%]">
                  <div className="absolute bg-indigo_301 lg:h-[26px] xl:h-[30px] h-[33px] 2xl:h-[34px] 3xl:h-[40px] left-[0] rounded-radius50 top-[0] lg:w-[25px] xl:w-[29px] w-[33px] 3xl:w-[39px]"></div>
                  <Text className="absolute bottom-[0] font-black right-[0] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`PFP of Leading Supporter`}</Text>
                </Stack>
                <Text className="font-normal leading-lh15000 lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:mt-[15px] xl:mt-[17px] mt-[20px] 3xl:mt-[24px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700_cc w-[56%]">{`For our first supporter who holds more than 3 Futuristic Christ NFTs, we shall create a unique PFP for their exclusive use. In honor of their support and belief, they shall be known amongst our community as a leading supporter.`}</Text>
                <Column className="lg:mt-[31px] xl:mt-[35px] mt-[40px] 3xl:mt-[48px] w-[100%]">
                  <List
                    className="gap-[0] min-h-[auto] w-[100%]"
                    orientation="vertical"
                  >
                    <Column className="justify-start lg:ml-[230px] xl:ml-[263px] ml-[296px] 3xl:ml-[355px] lg:my-[15px] xl:my-[17px] my-[20px] 3xl:my-[24px] w-[51%]">
                      <Stack className="lg:h-[39px] xl:h-[44px] h-[49px] 2xl:h-[50px] 3xl:h-[59px] xl:mr-[110px] mr-[124px] 3xl:mr-[148px] lg:mr-[96px] w-[61%]">
                        <div className="absolute bg-indigo_301 lg:h-[26px] xl:h-[30px] h-[33px] 2xl:h-[34px] 3xl:h-[40px] left-[0] rounded-radius50 top-[0] lg:w-[25px] xl:w-[29px] w-[33px] 3xl:w-[39px]"></div>
                        <Text className="absolute bottom-[0] font-black right-[0] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`Airdrop Access`}</Text>
                      </Stack>
                      <Text className="font-normal leading-lh15000 lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:mt-[15px] xl:mt-[17px] mt-[20px] 3xl:mt-[24px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700_cc w-[94%]">{`For all future mints, we shall award 1 FREE NFT to all of our followers who hold 3 or more Futuristic Christ NFTs.`}</Text>
                    </Column>
                    <Column className="justify-start lg:mr-[217px] xl:mr-[249px] mr-[280px] 3xl:mr-[336px] lg:my-[15px] xl:my-[17px] my-[20px] 3xl:my-[24px] w-[54%]">
                      <Stack className="lg:h-[39px] xl:h-[44px] h-[49px] 2xl:h-[50px] 3xl:h-[59px] lg:mr-[38px] xl:mr-[43px] mr-[49px] 3xl:mr-[58px] w-[85%]">
                        <div className="absolute bg-indigo_301 lg:h-[26px] xl:h-[30px] h-[33px] 2xl:h-[34px] 3xl:h-[40px] left-[0] rounded-radius50 top-[0] lg:w-[25px] xl:w-[29px] w-[33px] 3xl:w-[39px]"></div>
                        <Text className="absolute bottom-[0] font-black right-[0] lg:text-fs17 xl:text-fs19 text-fs22 3xl:text-fs26 text-left text-white_A700_e5 w-[auto]">{`More Utilities Coming…`}</Text>
                      </Stack>
                      <Text className="font-normal leading-lh15000 lg:ml-[15px] xl:ml-[17px] ml-[20px] 3xl:ml-[24px] lg:mt-[15px] xl:mt-[17px] mt-[20px] 3xl:mt-[24px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700_cc w-[94%]">{`This is only the beginning! As our project gains traction, we shall add more virtual and IRL utilities to be enjoyed by our beloved community. Stay tuned, He is coming!`}</Text>
                    </Column>
                  </List>
                </Column>
              </Column>
            </Row>
          </Stack>
          <div className="absolute backdrop-opacity-[0.4] bg-indigo_300 blur-[104.00px] bottom-[9%] 3xl:h-[118px] lg:h-[77px] xl:h-[88px] h-[98px] 2xl:h-[99px] left-[3%] rotate-[-180deg] rounded-radius50 3xl:w-[117px] lg:w-[76px] xl:w-[87px] w-[98px]"></div>
        </Stack>
        <Row className="bg-indigo_302 font-gothampro items-center justify-between xl:mt-[106px] mt-[120px] 3xl:mt-[144px] lg:mt-[93px] xl:px-[102px] px-[115px] 3xl:px-[138px] lg:px-[89px] xl:py-[106px] py-[120px] 3xl:py-[144px] lg:py-[93px] w-[100%]">
          <Column className="items-start lg:mb-[1px] mb-[2.5px] 2xl:mb-[2px] xl:mb-[2px] 3xl:mb-[3px] w-[41%]">
            <Text className="font-black lg:text-fs35 xl:text-fs40 text-fs45 3xl:text-fs54 text-left text-white_A700 uppercase w-[auto]">{`our socials`}</Text>
            <Line className="bg-white_A700 h-[2px] lg:mt-[21px] xl:mt-[24px] mt-[28px] 3xl:mt-[33px] w-[7%]" />
            <Text className="font-normal leading-lh15000 lg:mt-[20px] xl:mt-[23px] mt-[26px] 3xl:mt-[31px] lg:text-fs14 xl:text-fs16 text-fs18 3xl:text-fs21 text-left text-white_A700_e5 w-[100%]">
              <span className="text-white_A700_e5 font-gothampro font-medium">
                <>{`To discover more about The Global Architect Institute, visit our `}</>
              </span>
              <span className="text-gray_900 font-gothampro font-medium">
                <>{`website`}</>
              </span>
              <span className="text-white_A700_e5 font-gothampro font-medium">
                <>
                  {` and follow us on Twitter, Facebook, and Instagram.`}
                  <br />
                  {``}
                  <br />
                  {``}
                  <br />
                  {`For the latest updates about The Glory of Futuristic Christ, join our Twitter and Official Discord to be the first to hear of every exciting development!`}
                  <br />
                  {``}
                </>
              </span>
            </Text>
            <Row className="items-center justify-start 3xl:mt-[114px] lg:mt-[73px] xl:mt-[84px] mt-[95px] w-[92%]">
              <Row className="items-center justify-start w-[17%]">
                <Image
                  src={"images/img_twitter21.svg"}
                  className="lg:h-[19px] xl:h-[22px] h-[24px] 2xl:h-[25px] 3xl:h-[29px] mt-[1px] object-contain lg:w-[18px] xl:w-[21px] w-[24px] 3xl:w-[28px]"
                  alt="twitter21"
                />
                <Text className="font-medium mb-[1px] xl:ml-[10px] ml-[12px] 3xl:ml-[14px] lg:ml-[9px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700_e5 w-[auto]">{`Twitter`}</Text>
              </Row>
              <Image
                src={"images/img_asset21.svg"}
                className="lg:h-[19px] xl:h-[22px] h-[24px] 2xl:h-[25px] 3xl:h-[29px] mb-[1px] lg:ml-[31px] xl:ml-[35px] ml-[40px] 3xl:ml-[48px] object-contain lg:w-[18px] xl:w-[21px] w-[24px] 3xl:w-[28px]"
                alt="Asset21"
              />
              <Text className="font-medium mb-[1px] xl:ml-[10px] ml-[12px] 3xl:ml-[14px] lg:ml-[9px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700_e5 w-[auto]">{`Facebook`}</Text>
              <Image
                src={"images/img_asset20.svg"}
                className="lg:h-[19px] xl:h-[22px] h-[24px] 2xl:h-[25px] 3xl:h-[29px] mb-[1px] lg:ml-[31px] xl:ml-[35px] ml-[40px] 3xl:ml-[48px] object-contain lg:w-[18px] xl:w-[21px] w-[24px] 3xl:w-[28px]"
                alt="Asset20"
              />
              <Text className="font-medium mb-[1px] xl:ml-[10px] ml-[12px] 3xl:ml-[14px] lg:ml-[9px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700_e5 w-[auto]">{`Instagram`}</Text>
              <Row className="items-center justify-start mb-[1px] lg:ml-[31px] xl:ml-[35px] ml-[40px] 3xl:ml-[48px] w-[18%]">
                <Image
                  src={"images/img_asset21_1.svg"}
                  className="lg:h-[19px] xl:h-[22px] h-[24px] 2xl:h-[25px] 3xl:h-[29px] object-contain lg:w-[18px] xl:w-[21px] w-[24px] 3xl:w-[28px]"
                  alt="Asset21"
                />
                <Text className="font-medium xl:ml-[10px] ml-[12px] 3xl:ml-[14px] lg:ml-[9px] lg:text-fs12 xl:text-fs14 text-fs16 3xl:text-fs19 text-left text-white_A700_e5 w-[auto]">{`Discord`}</Text>
              </Row>
            </Row>
          </Column>
          <Stack className="lg:h-[298px] xl:h-[341px] h-[383px] 2xl:h-[384px] 3xl:h-[460px] lg:ml-[184px] xl:ml-[210px] ml-[237px] 3xl:ml-[284px] lg:w-[297px] xl:w-[340px] w-[383px] 3xl:w-[459px]">
            <div className="absolute bg-white_A700_19 border-2 border-solid border-white_A700 bottom-[0] lg:h-[278px] xl:h-[318px] h-[357px] 2xl:h-[358px] 3xl:h-[429px] right-[0] lg:w-[277px] xl:w-[317px] w-[357px] 3xl:w-[428px]"></div>
            <Image
              src={"images/img_dropmefilescom_1.png"}
              className="absolute lg:h-[278px] xl:h-[318px] h-[357px] 2xl:h-[358px] 3xl:h-[429px] left-[0] object-contain top-[0] lg:w-[277px] xl:w-[317px] w-[357px] 3xl:w-[428px]"
              alt="dropmefilescom"
            />
          </Stack>
        </Row>
        <Row className="bg-black_901 font-gothampro items-center justify-start lg:pb-[25px] xl:pb-[29px] pb-[33px] 3xl:pb-[39px] lg:pt-[26px] xl:pt-[30px] pt-[34px] 3xl:pt-[40px] xl:px-[102px] px-[115px] 3xl:px-[138px] lg:px-[89px] w-[100%]">
          <Image
            src={"images/img_group7667425.svg"}
            className="lg:h-[10px] xl:h-[11px] h-[12px] 2xl:h-[13px] 3xl:h-[15px] lg:my-[4px] xl:my-[5px] my-[6px] 3xl:my-[7px] object-contain w-[8%]"
            alt="Group7667425"
          />
          <Text className="font-light lg:mb-[3px] xl:mb-[4px] mb-[5px] 3xl:mb-[6px] lg:ml-[250px] xl:ml-[286px] ml-[322px] 3xl:ml-[386px] lg:mt-[4px] xl:mt-[5px] mt-[6px] 3xl:mt-[7px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700 w-[auto]">{`Terms of engagement`}</Text>
          <Text className="font-light lg:mb-[3px] xl:mb-[4px] mb-[5px] 3xl:mb-[6px] lg:ml-[28px] xl:ml-[32px] ml-[36px] 3xl:ml-[43px] lg:mt-[4px] xl:mt-[5px] mt-[6px] 3xl:mt-[7px] lg:text-fs10 xl:text-fs12 text-fs14 3xl:text-fs16 text-left text-white_A700 w-[auto]">{`Terms of service and privacy`}</Text>
          <Image
            src={"images/img_group7667422.svg"}
            className="lg:h-[19px] xl:h-[22px] h-[24px] 2xl:h-[25px] 3xl:h-[29px] lg:ml-[178px] xl:ml-[204px] ml-[230px] 3xl:ml-[276px] object-contain w-[11%]"
            alt="Group7667422"
          />
        </Row>
        <Stack className="font-gothampro lg:h-[25px] xl:h-[29px] h-[32px] 2xl:h-[33px] 3xl:h-[39px] w-[100%]">
          <div className="absolute bg-gray_900 lg:h-[25px] xl:h-[29px] h-[32px] 2xl:h-[33px] 3xl:h-[39px] inset-[0] w-[100%]"></div>
          <Text className="absolute font-light h-[max-content] inset-[0] justify-center m-[auto] xl:text-fs10 text-fs12 3xl:text-fs14 lg:text-fs9 text-left text-white_A700 w-[max-content]">{`© 2022 The Global Architect Institute.`}</Text>
        </Stack>
      </Column>
    </>
  );
};

export default NFTpagePage;
